
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, Input, Output, EventEmitter, TemplateRef, OnInit, OnDestroy, ElementRef, ViewChild, OnChanges } from "@angular/core";
import { WeekViewAllDayEvent as WeekViewAllDayAppointment, DayViewEvent } from "calendar-utils";
import { PlacementArray } from "positioning";
import { Subscription, Subject, take } from "rxjs";
import { CalenderUtils } from "../../utils/calender-utils";
import { CalendarRepository } from "../../repositories/calendar.repository";
import { CommonService } from "src/app/services/ common.service.";
import { BreakpointObserver } from "@angular/cdk/layout";

@Component({
  selector: "vp-calendar-week-view-event",
  templateUrl: "./calendar-week-view-event.component.html",
  styleUrls: ["./calendar-week-view-event.component.scss"],
})
export class CalendarWeekViewAppointmentComponent implements OnInit, OnDestroy {
  constructor(private calendarRepository: CalendarRepository,
    private el: ElementRef,
    private commonService: CommonService,
    private breakpointObserver: BreakpointObserver,
    ) {
      this.isMobileScreen = this.breakpointObserver.isMatched("(max-width: 599px)");

  }
  @Input() weekEvent: WeekViewAllDayAppointment | DayViewEvent;

  @Input() tooltipPlacement: PlacementArray;

  @Input() tooltipAppendToBody: boolean;

  @Input() tooltipDisabled: boolean;

  @Input() tooltipDelay: number | null;

  @Input() customTemplate: TemplateRef<any>;

  @Input() eventTitleTemplate: TemplateRef<any>;

  @Input() eventActionsTemplate: TemplateRef<any>;

  @Input() tooltipTemplate: TemplateRef<any>;

  @Input() refresh: Subject<any>;

  @Output() eventClicked: EventEmitter<any> = new EventEmitter();

  @Output() onContextMenuClicked: EventEmitter<any> = new EventEmitter();

  @Output() onDoubleClicked: EventEmitter<any> = new EventEmitter();

  @Output() longPressClick: EventEmitter<any> = new EventEmitter();

  refreshSubscription: Subscription;

  isSelecting: boolean = false;
  isMobileScreen: boolean = false;

  infoToolTipData: any;
  @ViewChild("eventDialog", {static: false}) eventDialog: ElementRef;
  hideTimer: NodeJS.Timeout;

  checkInst(weekEvent): boolean {
    if (weekEvent?.event?.inst?.length) {
      const filterInst = weekEvent?.event?.inst.filter(i => i?.fba);
      if (filterInst.length) return true;
    }
    return false;
  }

  ngOnInit(): void {
    console.log("=> weekEvent", this.weekEvent);
    if (this.refresh) {
      this.refreshSubscription = this.refresh.subscribe((res) => {
        if (this.weekEvent.event["eventId"] !== res.event["eventId"]) {
          this.isSelecting = false;
        }
      });
    }
  }


  ngOnDestroy(): void {
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }
  }

  private handleAppointmentClicked(event: any) {
    if (this.isSelecting) {
      this.isSelecting = false;
    } else {
      this.isSelecting = true;
    }

    this.eventClicked.emit({ isSelecting: this.isSelecting });
  }

  private handleDoubleClicked(event: any) {
    this.onDoubleClicked.emit({event: event});
  }

  getBackgroundColor(color: string): string {
    if (this.calendarRepository.daysInWeek === 1 && this.isMobileScreen) {
      let colour = color;
      if (color?.startsWith("#")) {
        const schemaColor = CalenderUtils._getColors(color);
        colour = schemaColor.standard.body.bgcolor;
      }
      return colour;
    } else {
      const grandient = CalenderUtils.getBackgroundGrandientColor(color);
      if (!!grandient) {
        return grandient;
      }
      return color;
    }

  }

  isCalendarCheck(folderId: string): boolean {
    const checkUncheck = this.calendarRepository.isCalendarCheck(folderId);
    return checkUncheck;
  }

  longPressEvent(ev: any): void {
    this.longPressClick.emit(ev);
  }
  openEventData(event, participant) {
    this.infoToolTipData = participant;

    this.showEventData();
    setTimeout(() => {
      let top = event.pageY + 20;
      if (top + 184 > document.body.clientHeight) {
        top = event.pageY - 184 - 48;
      }
      this.eventDialog.nativeElement.style.top = top + "px";
      this.eventDialog.nativeElement.style.left = (this.el.nativeElement.getBoundingClientRect().left + 10) + "px";
      this.eventDialog.nativeElement.style.visibility = "visible";
    }, 100);
  }

  showEventData() {
    if (this.hideTimer) {
      clearTimeout(this.hideTimer);
    }
  }

  hideEventData(forkHide?: boolean) {
    this.infoToolTipData = null;

    // if (forkHide && this.eventDialog?.nativeElement) {
    //   this.infoToolTipData = null;
    // } else {
    //   this.hideTimer = setTimeout(() => {
    //     this.infoToolTipData = null;
    //   }, 500);
    // }
  }

}
