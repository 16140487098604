
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { environment } from "../../../environments/environment";
import { HttpHeaders } from "@angular/common/http";
import { MailConstants } from "./mail-constants";
import { ElectronService } from "src/app/services/electron.service";
import * as SentryBrowser from "@sentry/browser";
import { MailUtils } from "src/app/mail/utils/mail-utils";
import { take, Subject } from "rxjs";
import { Appointment } from "../models/appoinment.model";
import Autolinker from "autolinker";
import linkifyHtml from "linkify-html";
import * as mime from "mime-types";

SentryBrowser.init({
  dsn: MailConstants.SENTRY_DSN,
  sendClientReports: false,
  autoSessionTracking: false
  // dsn: "https://c9800a0fc5cf4c2a92724b270c6af9eb@sentry-web.dev-k8s.vnc.de/3",
});
const SENTRY_LOG_DOMAIN = "vncmail.vnc.biz";

const EMAIL_REGEXP =
/^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/;

export enum MediaType {
  IMAGE = "image",
  VOICE_MESSAGE = "voice-messages",
  ATTACHMENTS = "attachments",
  VIDEOS = "videos",
  DOCUMENTS = "docs",
  ALL = "all",
  PDF = "pdf",
  DOC = "doc",
  TEXT = "txt",
  EXCEL = "excel",
  PPT = "ppt",
  RAR = "rar",
  ZIP = "zip"
}
export class CommonUtils {
  static USER_PALETTE = {
    A: "#F24336",
    B: "#6358C7",
    C: "#EF417B",
    D: "#388EDA",
    E: "#24A3E3",
    F: "#00B8D4",
    G: "#27AB7F",
    H: "#84C620",
    I: "#FDB611",
    J: "#FA864C",
    K: "#A8B0B9",
    L: "#F24336",
    M: "#6358C7",
    N: "#EF417B",
    O: "#388EDA",
    P: "#24A3E3",
    Q: "#00B8D4",
    R: "#27AB7F",
    S: "#84C620",
    T: "#FDB611",
    U: "#FA864C",
    V: "#A8B0B9",
    W: "#F24336",
    X: "#6358C7",
    Y: "#EF417B",
    Z: "#388EDA"
  };
  static imagesExtensions = ["jpeg", "jpg", "gif", "png", "bmp", "tiff"];
  static CONFERENCE_LINK_PART = "/vncmeet/join/";
  static CONFERENCE_LINK_CLICK_EVENT = "vncmeet-join-link-click";
  static decimalNcr = {
    ä: "&#228;",
    ö: "&#246;",
    ü: "&#252;",
    Ä: "&#196;",
    Ö: "&#214;",
    Ü: "&#220;",
    ß: "&#223;",
    ẞ: "&#7838;"
  };

  static isImage(fileName: string): boolean {
    const extension = fileName.substr((fileName.lastIndexOf(".") + 1));
    return this.imagesExtensions.indexOf(extension.toLowerCase()) !== -1;
  }

  static getMediaType(body: string): string {
    if (!body) {
      return MediaType.ATTACHMENTS;
    }
    if ((body.lastIndexOf("aac") === body.length - 3) ||
      (body.lastIndexOf("amr") === body.length - 3) ||
      (body.lastIndexOf("aiff") === body.length - 4) ||
      (body.lastIndexOf("flac") === body.length - 4) ||
      (body.lastIndexOf("mp3") === body.length - 3) ||
      (body.lastIndexOf("oog") === body.length - 3) ||
      (body.lastIndexOf("wma") === body.length - 3) ||
      (body.lastIndexOf("wav") === body.length - 3)) {
      return MediaType.VOICE_MESSAGE;
    }

    if ((body.lastIndexOf("jpeg") === body.length - 4) ||
      (body.lastIndexOf("jpg") === body.length - 3) ||
      (body.lastIndexOf("gif") === body.length - 3) ||
      (body.lastIndexOf("png") === body.length - 3) ||
      (body.lastIndexOf("bmp") === body.length - 3) ||
      (body.lastIndexOf("svg") === body.length - 3) ||
      (body.lastIndexOf("tiff") === body.length - 4) ||
      (body.lastIndexOf("ico") === body.length - 3) ||
      (body.lastIndexOf("odi") === body.length - 3)) {
      return MediaType.IMAGE;
    }

    if ((body.lastIndexOf("3gp") === body.length - 3) ||
      (body.lastIndexOf("avi") === body.length - 3) ||
      (body.lastIndexOf("flv") === body.length - 3) ||
      (body.lastIndexOf("m4v") === body.length - 3) ||
      (body.lastIndexOf("mkv") === body.length - 3) ||
      (body.lastIndexOf("mov") === body.length - 3) ||
      (body.lastIndexOf("mp4") === body.length - 3) ||
      (body.lastIndexOf("mpeg") === body.length - 4) ||
      (body.lastIndexOf("mpg") === body.length - 3) ||
      (body.lastIndexOf("mts") === body.length - 3) ||
      (body.lastIndexOf("rm") === body.length - 2) ||
      (body.lastIndexOf("vob") === body.length - 3) ||
      (body.lastIndexOf("wmv") === body.length - 3)) {
      return MediaType.VIDEOS;
    }

    if (body.lastIndexOf("pdf") === body.length - 3) {
      return MediaType.PDF;
    }

    if ((body.lastIndexOf("txt") === body.length - 3) ||
      (body.lastIndexOf("rtf") === body.length - 3) ||
      (body.lastIndexOf("dat") === body.length - 3)) {
      return MediaType.TEXT;
    }

    if ((body.lastIndexOf("doc") === body.length - 3) ||
      (body.lastIndexOf("docx") === body.length - 4) ||
      (body.lastIndexOf("docm") === body.length - 4) ||
      (body.lastIndexOf("odt") === body.length - 3)) {
      return MediaType.DOC;
    }

    if ((body.lastIndexOf("xls") === body.length - 3) ||
      (body.lastIndexOf("xlr") === body.length - 3) ||
      (body.lastIndexOf("xlsx") === body.length - 4) ||
      (body.lastIndexOf("xlsm") === body.length - 3) ||
      (body.lastIndexOf("ods") === body.length - 3) ||
      (body.lastIndexOf("csv") === body.length - 3) ||
      (body.lastIndexOf("tsv") === body.length - 3)) {
      return MediaType.EXCEL;
    }

    if ((body.lastIndexOf("ppt") === body.length - 3) ||
      (body.lastIndexOf("pptx") === body.length - 4) ||
      (body.lastIndexOf("pps") === body.length - 3) ||
      (body.lastIndexOf("odp") === body.length - 3)) {
      return MediaType.PPT;
    }

    if ((body.lastIndexOf("rar") === body.length - 3) ||
      (body.lastIndexOf("tar") === body.length - 3)) {
      return MediaType.RAR;
    }

    if ((body.lastIndexOf("zip") === body.length - 3) ||
      (body.lastIndexOf("7z") === body.length - 2)) {
      return MediaType.ZIP;
    }

    return MediaType.ATTACHMENTS;
  }

  static getMimeType(extension): string {
    const mType = mime.lookup(extension);
    if (typeof mType === "string") {
      return mType;
    }
    return "unknown";
  }

  static getBaseUrl(app?: string) {
    const baseUrl = window.location.href;

    if (environment.isCordova) {
      const split = baseUrl.startsWith("file://") ? "/www" : "/" + window.location.href.split("app://localhost/")[1].split("/")[0];
      return (CommonUtils.isOnAndroid() || baseUrl.startsWith("file://")) ? window.location.href.split("/www")[0] + "/www" : window.location.href.split(split)[0].replace(/index.html/gi, "");
    } else if (environment.isElectron) {
      if (app && app === "contacts") {
        return baseUrl.includes("/index.html") ? baseUrl.split("/index.html")[0] : baseUrl.split("/contacts")[0];
      } else if (app && app === "calendar") {
        return baseUrl.includes("/index.html") ? baseUrl.split("/index.html")[0] : baseUrl.split("/calendar")[0];
      } else if (app && app === "briefcase") {
        return baseUrl.includes("/index.html") ? baseUrl.split("/index.html")[0] : baseUrl.split("/briefcase")[0];
      } else if (app && app === "preferences") {
        return baseUrl.includes("/index.html") ? baseUrl.split("/index.html")[0] : baseUrl.split("/preferences")[0];
      } else {
        return baseUrl.includes("/index.html") ? baseUrl.split("/index.html")[0] : baseUrl.split("/app.asar")[0] + "/app.asar";
      }
    } else {
      return "";
    }
  }

  static getFullUrl(url: string, app?: string) {
    return CommonUtils.getBaseUrl(app) + url;
  }

  static getZimbraHeader(): HttpHeaders {
    const electronService: ElectronService = new ElectronService();
    const headers: HttpHeaders = new HttpHeaders();
    if (environment.isCordova || environment.isElectron) {
      const token = localStorage.getItem(MailConstants.TOKEN);
      return headers.set("Authorization", token).set("Content-Type", "application/json").set("Accept", "application/json");
    }
    return headers.set("Content-Type", "application/json").set("Accept", "application/json");
  }

  static getShortNameForMobile(str: string, size: number): string {
    let shortName: string = "";
    if (this.isOnMobileDevice()) {
      shortName = str.substr(0, size) + (str.length > 10 ? "...." : "");
    } else {
      shortName = str;
    }
    return shortName;
  }

  static htmlEscape(str: string): string {
    return str
        .replace(/&/g, "&amp;")
        .replace(/"/g, "&quot;")
        .replace(/'/g, "&#39;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;");
  }

  static isOnMobileDevice() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|PlayBook/i
      .test(navigator.userAgent);
  }

  static isOnMobilePhone() {
    return /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini|PlayBook/i
      .test(navigator.userAgent);
  }

  static isOnIOSMobile() {
    return /iPhone|iPad|iPod/i
      .test(navigator.userAgent);
  }

  static isOnIpad() {
    return /iPad/i
      .test(navigator.userAgent);
  }

  static isOnIOS() {
    return typeof device !== "undefined" && device.platform && device.platform.toUpperCase() === "IOS";
  }

  static isOnAndroid() {
    return typeof device !== "undefined" && device.platform && device.platform.toUpperCase() === "ANDROID";
  }

  static isOnCordova() {
    return typeof cordova !== "undefined";
  }

  static isOnNativeMobileDevice() {
    return CommonUtils.isOnIOS() || CommonUtils.isOnAndroid();
  }

  static isSQLSupported() {
    // TODO: temporary disabled SQlite for Electron due to some perf issues.
    //
    // return this.isOnNativeMobileDevice();
    // return environment.isCordova || environment.isElectron;
    return false;
  }


  static isOfflineModeSupported() {
    let idbtestflag = localStorage.getItem("idbsupport");
    const isCordovaOrElectron = (environment.isCordova || environment.isElectron);
    if ((window.location !== window.parent.location) && !isCordovaOrElectron) {
      return false;
    } else {
      return ("indexedDB" in window && (environment.theme !== "dfb") && (idbtestflag === "yes"));
    }
  }

  static getAvatarBackground(text: string): string {
    return this.USER_PALETTE[text.charAt(0)];
  }

  static isInsideIFrame() {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  static rmap(obj, pobj) {
    return Object.entries(obj).reduce((acc, [key, value]) => {
      if (!(key in pobj)) return { ...acc, [key]: value };
      if (typeof pobj[key] === "object" && pobj[key] !== null && !Array.isArray(pobj[key])) {
        return { ...acc, [key]: this.rmap(obj[key], pobj[key]) };
      } else {
        return { ...acc, [key]: pobj[key] };
      }
    }, {});
  }

  static escapeHTMLString(unescapedString: string): string {
    if (!unescapedString) {
      return "";
    }
    return unescapedString
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;");
  }

  static unEscapeHTMLString(str: string): string {
    if (!str) {
      return "";
    }
    return str
      .replace(/&amp;/g, "&")
      .replace(/&lt;/g, "<")
      .replace(/&gt;/g, ">");
  }

  static copyToClipboard(stringArray: String[]) {
    const str = stringArray.join("\n");
    if (environment.isCordova) {
      cordova.plugins.clipboard.copy(str);
    } else {
      // const event = (e: ClipboardEvent) => {
      //   e.clipboardData.setData("text/plain", str);
      //   e.preventDefault();
      //   document.removeEventListener("copy", event);
      // };
      // document.addEventListener("copy", event);
      // document.execCommand("copy");
      window.Clipboard = (function (window, document, navigator) {
        let textArea,
          copy;

        function isOS() {
          return navigator.userAgent.match(/ipad|iphone/i);
        }

        function createTextArea(text) {
          textArea = document.createElement("textArea");
          textArea.value = text;
          document.body.appendChild(textArea);
        }

        function selectText() {
          let range,
            selection;

          if (isOS()) {
            range = document.createRange();
            range.selectNodeContents(textArea);
            selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);
            textArea.setSelectionRange(0, 999999);
          } else {
            textArea.select();
          }
        }

        function copyToClipboard() {
          document.execCommand("copy");
          document.body.removeChild(textArea);
        }

        copy = function (text) {
          createTextArea(text);
          selectText();
          copyToClipboard();
        };

        return {
          copy: copy
        };
      })(window, document, navigator);
      window.Clipboard.copy(str);
    }
  }

  static escapeCarriagesAndNewline(unescapedString: string): string {
    return unescapedString.replace(/\r?\n/g, " ");
  }

  static getIcon(appName: string): string {
    let icon = "";
    let name = appName.replace("vnc", "VNC");
    switch (appName.toLowerCase()) {
      case "vncmail": icon = CommonUtils.getFullUrl("/assets/icon/VNCmail.svg"); break;
      case "vnctask": icon = CommonUtils.getFullUrl("/assets/icon/VNCtask.svg"); break;
      case "vncmcb": icon = CommonUtils.getFullUrl("/assets/icon/VNCmcb.svg"); break;
      case "vnccontacts": icon = CommonUtils.getFullUrl("/assets/icon/VNCcontacts.svg"); break;
      case "vnctalk": icon = CommonUtils.getFullUrl("/assets/icon/VNCtalk.svg"); break;
      case "vnccalendar": icon = CommonUtils.getFullUrl("/assets/icon/VNCcalendar.svg"); break;
      case "vncproject": icon = CommonUtils.getFullUrl("/assets/icon/VNCproject.svg"); break;
      case "vncbriefcase": icon = CommonUtils.getFullUrl("/assets/icon/VNCbriefcase.svg"); break;
      default: icon = CommonUtils.getFullUrl("/assets/icon/" + name + ".svg"); break;
    }
    return icon;
  }

  static getAppStoreOrWeblink(appItem: any) {
    let appLink = appItem.path;
    if (CommonUtils.isOnAndroid()) {
      switch (appItem.name.toLowerCase()) {
        case "vncmail": appLink = "https://play.google.com/store/apps/details?id=biz.vnc.vncmail"; break;
        case "vnccalendar": appLink = "https://play.google.com/store/apps/details?id=biz.vnc.vncmail"; break;
        case "vnccontacts": appLink = "https://play.google.com/store/apps/details?id=biz.vnc.contactsplus"; break;
        case "vnctalk": appLink = "https://play.google.com/store/apps/details?id=biz.vnc.vnctalk"; break;
        case "vnctask": appLink = "https://play.google.com/store/apps/details?id=biz.vnc.vnctask"; break;
        default: appLink = appItem.path;
      }
    } else if (CommonUtils.isOnIOS()) {
      switch (appItem.name.toLowerCase()) {
        case "vncmail": appLink = "https://apps.apple.com/us/app/vncmail-email-communication/id1448862526"; break;
        case "vnccalendar": appLink = "https://apps.apple.com/us/app/vncmail-email-communication/id1448862526"; break;
        case "vnccontacts": appLink = "https://apps.apple.com/us/app/vnccontacts/id1536718018"; break;
        case "vnctalk": appLink = "https://apps.apple.com/us/app/vnctalk/id1400937435"; break;
        case "vnctask": appLink = "https://apps.apple.com/us/app/vnctask/id1423089930"; break;
        default: appLink = appItem.path;
      }
    }
    return appLink;
  }

  static getPackageName(appName: string): string {
    const packageName = `biz.vnc.${appName.toLowerCase()}`;
    console.log("getPackageName", appName, packageName);
    return packageName;
  }

  static isValidAvtarSize(file) {
    if (file.size < 2000000) {
      return true;
    } else {
      return false;
    }
  }

  static isvalidCsvByExtension(file) {
    if (file.name.match(/.(csv|vcf)$/i)) {
      return true;
    }
    return false;
  }

  static isvalidFileByExtension(file): boolean {
    if (file.name.match(/.(jpg|jpeg|png|gif|svg)$/i)) {
      return true;
    }
    return false;
  }

  static getAppUrl(appName: string): string {
    const availableiOSApps = {
      "vncmail": "",
      "vnctask": "itms-apps://itunes.apple.com/app/id1423089930",
      "vnccontacts": ""
    };
    const availableAndroidApps = {
      "vncmail": "",
      "vnctask": "market://details?id=biz.vnc.vnctask",
      "vnccontacts": ""
    };
    if (!CommonUtils.isOnAndroid()) {
      return availableiOSApps[appName.toLowerCase()];
    }
    return availableAndroidApps[appName.toLowerCase()];
  }

  static requestPermissions(): void {
    if (!cordova.plugins.permissions) {
      return;
    }
    const permissions = cordova.plugins.permissions;
    const checkVideoPermissionCallback = function (status) {
      if (!status.hasPermission) {
        const errorCallback = function () {
          console.log("[root.component]  Camera permission is not turned on");
        };
        permissions.requestPermission(
          permissions.CAMERA,
          function (s) {
            if (!s.hasPermission) {
              errorCallback();
            }
          },
          errorCallback);
      }
    };
    const checkAudioPermissionCallback = function (status) {
      if (!status.hasPermission) {
        const errorCallback = function () {
          console.log("[root.component] Audio permission is not turned on");
        };
        permissions.requestPermission(
          permissions.RECORD_AUDIO,
          function (s) {
            if (!s.hasPermission) {
              errorCallback();
            }
          },
          errorCallback);
      }
    };
    const checkWriteExternalStoragePermissionCallback = function (status) {
      if (!status.hasPermission) {
        const errorCallback = function () {
          console.log("[root.component] Write external storage permission is not turned on");
        };
        permissions.requestPermission(
          permissions.WRITE_EXTERNAL_STORAGE,
          function (s) {
            console.log("permissions requested HEAD_EXTERNAL_STORAGE ", s);
            if (!s.hasPermission) {
              errorCallback();
            }
          },
          errorCallback);
      } else {
        console.log("permissions: got WRITE_EXTERNAL_STORAGE");      }
    };
    let checkReadExternalStoragePermissionCallback = function (status) {
      if (!status.hasPermission) {
        let errorCallback = function () {
          // eslint-disable-next-line no-console
          console.error("[root.component] Read external storage permission is not turned on");
        };
        permissions.requestPermission(
          permissions.READ_EXTERNAL_STORAGE,
          function (s) {
            console.log("permissions requested READ_EXTERNAL_STORAGE ", s);
            if (!s.hasPermission) {
              errorCallback();
            }
          },
          errorCallback);
      } else {
        console.log("permissions: got READ_EXTERNAL_STORAGE");
      }
    };

    permissions.checkPermission(permissions.READ_EXTERNAL_STORAGE, checkReadExternalStoragePermissionCallback, null);
    permissions.checkPermission(permissions.WRITE_EXTERNAL_STORAGE, checkWriteExternalStoragePermissionCallback, null);
    permissions.checkPermission(permissions.CAMERA, checkVideoPermissionCallback, null);
    permissions.checkPermission(permissions.RECORD_AUDIO, checkAudioPermissionCallback, null);
  }

  static addTokenToRequest(url: string, forceAddToken?: boolean): string {
    if (url.indexOf("&token=") === -1 && (environment.isCordova || environment.isElectron || forceAddToken)) {
      const token = localStorage.getItem("token");
      url += `&token=${token}`;
    }
    return url;
  }

  static forceAddTokenToRequest(url: string): string {
    if (url.indexOf("&token=") === -1 ) {
      const token = localStorage.getItem("token");
      url += `&token=${token}`;
    }
    return url;
  }

  static sentryLog(text: string, time: number) {
    // disable perf logging to sentry as it spams
    if (!environment.production) {
      const profileUser = localStorage.getItem("profileUser");
      if (!!profileUser) {
        try {
          const currentUser = MailUtils.parseUserProfile(profileUser);
          text = `[EMAIL] ${currentUser.email} - ${text}`;
        } catch (ex) {
        }
      }
      if (navigator.connection && navigator.connection.downlink) {
        text = `[downlink] ${navigator.connection.downlink} ${text}`;
      }
      // console.log("[sentryLog]", text);
      const serverURL = localStorage.getItem("serverURL");
      const isCordovaWithLiveServer = (environment.isCordova || environment.isElectron)
      && !!serverURL && serverURL.indexOf(SENTRY_LOG_DOMAIN) !== -1;
      if ((isCordovaWithLiveServer || window.location.href.indexOf(SENTRY_LOG_DOMAIN) !== -1)
      && time > MailConstants.TIME_TO_SAVE_LOG) {
        try {
          console.log("[common-util][sentryLog] message: ", text);
          SentryBrowser.captureMessage(text);
        } catch (ex) {
          console.error(ex);
        }
      }
    }
  }

  static getChildFolders(folders: any[]): any[] {
    let allFolders: any[] = [];
    let childFolders: any[] = [];
    folders.filter(f => f.children && f.children.length > 0).forEach(f => {
        childFolders = [...childFolders, ...f.children];
        childFolders = [...childFolders, ...MailUtils.getChildFolders(f.children)];
    });
    return childFolders;
  }

  static getRandomAvatarColor() {
    const colors = this.getAvatarbgColors();
    const random = Math.floor(Math.random() * colors.length);
    return colors[random];
  }

  static getAvatarbgColors() {
    return [
      "#F44336",
      "#E91E63",
      "#9C27B0",
      "#673AB7",
      "#3F51B5",
      "#2196F3",
      "#03A9F4",
      "#00BCD4",
      "#009688",
      "#4CAF50",
      "#8BC34A",
      "#FF9800",
      "#FF5722",
      "#795548",
      "#9E9E9E",
      "#607D8B"
    ];
  }

  static getBase6ImageFromUrl(url) {
    const subject = new Subject();
    const image: HTMLImageElement = new Image();
    image.src = url;
    image.crossOrigin = "Anonymous";
    image.onload = () => {
      const canvas: HTMLCanvasElement = document.createElement("canvas");
      const context: CanvasRenderingContext2D = canvas.getContext("2d");
      canvas.height = image.naturalHeight;
      canvas.width = image.naturalWidth;
      context.drawImage(image, 0, 0, canvas.width, canvas.height);
      const b64url = canvas.toDataURL();
      subject.next(b64url);
    };
    image.onerror = (error) => {
      subject.error(error);
    };
    return subject.asObservable().pipe(take(1));
  }

  static mapAppointmentFromMsg(appointmentResponse: any): Appointment {
    const appointMent: any = {};
    appointMent.id = appointmentResponse.id;
    appointMent.d = appointmentResponse.d;
    appointMent.f = appointmentResponse.f;
    appointMent.l = appointmentResponse.l;
    appointMent.md = appointmentResponse.md;
    appointMent.ms = appointmentResponse.ms;
    appointMent.rev = appointmentResponse.rev;
    appointMent.t = appointmentResponse.t;
    appointMent.s = appointmentResponse.s;
    appointMent.tn = appointmentResponse.tn;
    if (appointmentResponse.mp) {
      appointMent.mp = appointmentResponse.mp;
    }
    if (appointmentResponse.inv && appointmentResponse.inv[0].comp && Array.isArray(appointmentResponse.inv[0].comp)) {
      const component = appointmentResponse.inv[0].comp[0];
      appointMent.alarmData = component.alarm;
      appointMent.apptId = component.apptId;
      if (component.at) {
        appointMent.at = component.at;
      }
      appointMent.calItemId = component.calItemId;
      appointMent.ciFolder = component.ciFolder;
      appointMent.class = component.class;
      appointMent.compNum = component.compNum;
      appointMent.desc = "";
      appointMent.descHTML = "";
      if (component.desc) {
        appointMent.desc = component.desc[0]._content;
      }
      if (component.descHtml) {
        appointMent.descHTML = component.descHtml[0]._content;
      }
      if (component.draft) {
        appointMent.draft = component.draft;
      }
      appointMent.fb = component.fb;
      appointMent.fba = component.fba;
      appointMent.name = component.name;
      if (component.or) {
        appointMent.or = component.or;
      }
      if (component.s) {
        appointMent.startDateData = component.s;
      }
      if (component.e) {
        appointMent.endDateData = component.e;
      }
      appointMent.seq = component.seq;
      appointMent.status = component.status;
      appointMent.transp = component.transp;
      appointMent.uid = component.uid;
      appointMent.x_uid = component.x_uid;
      appointMent.xprop = component.xprop;
      appointMent.url = component.url;
      appointMent.isOrg = component.isOrg;
      appointMent.allDay = false;
      if (component.allDay) {
        appointMent.allDay = component.allDay;
      }
      appointMent.loc = "";
      if (component.loc) {
        appointMent.loc = component.loc;
      }
      if (component.recur) {
        appointMent.recur = component.recur;
      }
      if (component.neverSent) {
        appointMent.neverSent = component.neverSent;
      }
    }
    if (appointmentResponse.inv && appointmentResponse.inv[0].replies) {
      const reply = appointmentResponse.inv[0].replies[0];
      appointMent.reply = reply.reply;
    }
    return appointMent;
  }

  static parseRedmineMentions(prefix: string, text: string): string[] {
    if (!text) {
      return [];
    }
    const mentionsRegex = new RegExp(prefix + "#([a-zA-Z0-9\_\.\-]+@[a-zA-Z0-9\_\.\-]+)", "gim");
    // LoggerService.info("[parseMentions]", mentionsRegex, text);
    let matches = text.match(mentionsRegex);
    if (matches && matches.length) {
        return matches.filter(v => CommonUtils.isValidEmail(v));
    } else {
        return [];
    }
  }

  static isValidEmail(email: string): boolean {
    return EMAIL_REGEXP.test(email);
  }
  static processHTMLBody(htmlBody) {
    if (!htmlBody) {
      return "";
    }
    return  htmlBody.replace("<body xmlns=\"http://www.w3.org/1999/xhtml\">", "")
    .replace("</body>", "")
    .replace(";lt;/body&amp;gt;", "")
    .replace(/&amp;nbsp;/ig, " ")
    .replace(/&lt;/ig, "<")
    .replace(/&gt;/ig, ">")
    .replace(/&quot;/ig, "\"")
    .replace(/&amp;lt;/ig, "&lt;")
    .replace(/&amp;gt;/ig, "&gt;")
    .replace(/&amp;amp;quote;/g, "&quote;")
    .replace(/&amp;amp;trade;/g, "&trade;")
    .replace(/&amp;amp;copy;/g, "&copy;")
    .replace(/&amp;amp;/ig, "&amp;");
  }
  static linkifyMe(inputText: string): string {
    if (!inputText) {
      return "";
    }
    const autolinker = new Autolinker({
      urls: {
        schemeMatches: true,
        tldMatches: true
      },
      email: false,
      phone: false,
      mention: false,
      hashtag: false,

      stripPrefix: false,
      stripTrailingSlash: false,
      newWindow: true,

      truncate: {
        length: 0,
        location: "end"
      },

      className: "open-new-window"
    });

    return autolinker.link(inputText);
  }

  static linkify(inputText: string): string {
    if (!inputText) {
      return "";
    }

    let result = this.linkifyText2Html(inputText).replace(/<a href/ig, "<a class=\"open-new-window\" href");
    return result;
  }

  static linkifyHTML(inputText: string): string {
    if (!inputText) {
        return "";
    }
    return linkifyHtml(inputText, {
        defaultProtocol: "http",
        className: "open-new-window",
        target: {
            url: "_blank",
            email: null
        },
        formatHref: (href, type) => {
            if (type !== "email") {
                return href;
            }
            return "javascript:void(0)";
        },
        tagName: (href, type) => {
            if (type !== "email") {
                return "a";
            }
            return "span";
        }
    });
  }

  static linkifyText2Html(inputText: string) {
    // LoggerService.info("[CommonUtil][linkifyText2Html]", inputText);
    if (!inputText) {
      return "";
    }
    const isConfJoinLink = this.isOnIOS() && inputText.includes(this.CONFERENCE_LINK_PART);
    // const isConfJoinLink = inputText.includes(ConstantsUtil.CONFERENCE_LINK_PART);
    let link = linkifyHtml(inputText, {
      defaultProtocol: "http",
      className: "open-new-window",
      target: {
        url: isConfJoinLink ? "_self" : "_blank",
        email: null
      },
      attributes: (href, type) => {
        if (type === "url") {
          const attrs = { rel: "noopener" };
          if (isConfJoinLink) {
            // https://stackoverflow.com/a/20548330/574475
            attrs["onclick"] = `const evv = new CustomEvent('${this.CONFERENCE_LINK_CLICK_EVENT}', { 'detail': '${href}' }); document.dispatchEvent(evv);`;
          }
          return attrs;
        }
        return {};
      },
      formatHref: (href, type) => {
        if (type !== "email" && !isConfJoinLink) {
          return href;
        }
        return "javascript:void(0)";
      },
      tagName: (href, type) => {
        if (type !== "email") {
          return "a";
        }
        return "span";
      }
    });

    // LoggerService.info("[linkify]", inputText, link)
    if (link.indexOf(`<span href="javascript:void(0)" class="open-new-window">`) !== -1) {
      link = link.replace(new RegExp(`<span href="javascript:void\\(0\\)" class="open-new-window">`, "igm"), "")
        .replace("</span>", "");
    }
    return link;
  }

  static processEmoji(messageBody) {
    if (!messageBody) {
      return "";
    }
    if (wdtEmojiBundle && wdtEmojiBundle.emoji) {
      wdtEmojiBundle.emoji.replace_mode = "unified";
      wdtEmojiBundle.emoji.allow_native = true;
    }
    return wdtEmojiBundle.render(messageBody.replace(/<br \/>/g, " ____<br />____ ")).replace(new RegExp(" ____<br />____ ", "g"), "<br />");
  }

  static generateCachedBodyRedmine(messageBody, highlight?: string, skipEmoji?: boolean) {
    if (!messageBody) {
      // console.warn("[CommonUtil][generateCachedBody] empty message body, ignore1");
      return "";
    }
    const jwt = !!localStorage.getItem("jwtTemp") ? localStorage.getItem("jwtTemp") : "";
    messageBody = messageBody.replace(/\?jwt=REDMINE_JWT/g, "?jwt=" + jwt);
    messageBody = CommonUtils.processHTMLBody(messageBody);
    messageBody = CommonUtils.linkifyMe(messageBody).replace(/\r?\n/g, "\\n").replace(/\\n/g, " <br />");
    if (highlight) {
      messageBody = CommonUtils.highlightSearch(messageBody, highlight);
    }
    if (!skipEmoji) {
      messageBody = CommonUtils.processEmoji(messageBody.replace(/<\/p>/ig, " </p>").replace(/<p>:/ig, "<p> :"));
    }
    return messageBody.replace(/<a href=/g, "<a target=\"_blank\" class=\"open-new-window\" href=")
      .replace(/<a class="([a-z\s0-9]*)"\shref=/g, "<a target=\"_blank\" class=\"open-new-window\" href=");
  }

  static generateCachedBody(messageBody, highlight?: string, skipEmoji?: boolean) {
    if (!messageBody) {
      // console.warn("[CommonUtil][generateCachedBody] empty message body, ignore1");
      return "";
    }
    if (messageBody.indexOf("meta_task_mention") !== -1 || messageBody.indexOf("ticket_mention") !== -1 || messageBody.indexOf("?jwt=REDMINE_JWT") !== -1) {
      return CommonUtils.generateCachedBodyRedmine(messageBody, highlight);
    }

    // const t0 = performance.now();

    messageBody = CommonUtils.processHTMLBody(messageBody);
    messageBody = CommonUtils.linkify(messageBody).replace(/\r?\n/g, "\\n").replace(/\\n/g, " <br />").replace(/user active/g, "open-new-window");
    if (highlight) {
      messageBody = CommonUtils.highlightSearch(messageBody, highlight);
    }
    if (!skipEmoji) {
      messageBody = CommonUtils.processEmoji(messageBody.replace(/<\/p>/ig, " </p>").replace(/<p>:/ig, "<p> :"));
    }

    // const t1 = performance.now();

    // save cached content
    return messageBody;
  }

  static convertToNcr(searchKeyword) {
    if (!searchKeyword) {
      return "";
    }
    let ncrKeyword = "";
    let singleChar;
    for (let i = 0; i < searchKeyword.length; i++) {
      singleChar = searchKeyword.charAt(i);
      if (this.decimalNcr[singleChar]) {
        ncrKeyword += this.decimalNcr[singleChar];
      } else {
        ncrKeyword += singleChar;
      }
    }
    return ncrKeyword;
  }

  static toUnicode(searchKeyword) {
    if (!searchKeyword) {
      return "";
    }
    let unicodeString = "";
    for (let i = 0; i < searchKeyword.length; i++) {
      let theUnicode = searchKeyword.charCodeAt(i).toString(16).toUpperCase();
      while (theUnicode.length < 4) {
        theUnicode = "0" + theUnicode;
      }
      theUnicode = "\\u" + theUnicode;
      unicodeString += theUnicode;
    }
    return unicodeString;
  }

  static highlightSearch(text, keyword) {
    if (!text) {
      return "";
    }
    if (!keyword) {
      return text;
    }
    let newText = text;
    try {
      text = text.replace(/&#34;/g, "\"");
      if (keyword.includes("ä" || "ö" || "ü" || "ß" || "Ä" || "Ö" || "Ü" || "ẞ")) {
        keyword = this.convertToNcr(keyword);
      }
      newText = text;
      const query = new RegExp(this.toUnicode(keyword), "gim");
      const doc = document.createElement("div");
      doc.innerHTML = newText;
      for (let dom of Array.from(doc.childNodes)) {
        if (dom.nodeValue) {
          dom.nodeValue = dom.nodeValue.replace(/(<span>|<\/span>)/igm, "").replace(query, "START_HIGHLIGHT$&END_HIGHLIGHT");
        } else if (dom.textContent) {
          dom.textContent = dom.textContent.replace(/(<span>|<\/span>)/igm, "").replace(query, "START_HIGHLIGHT$&END_HIGHLIGHT");
        }
      }
      // LoggerService.info("[highlightSearch]", doc.childNodes);
      newText = doc.innerHTML.replace(/START_HIGHLIGHT/g, "<span class=\"highlight\">").replace(/END_HIGHLIGHT/g, "</span>");
    } catch (error) {
      console.log("[highlightSearch] error", error);
    }
    return newText;
  }

  static parseMentions(text: string): string[] {
    if (!text) {
      return [];
    }
    const mentionsRegex = new RegExp("@([a-zA-Z0-9\_\.\-]+@[a-zA-Z0-9\_\.\-]+)", "gim");
    let matches = text.match(mentionsRegex);
    if (matches && matches.length) {
      let matchess = matches.map((match) => {
        return match.slice(1);
      });
      return CommonUtils.uniq(matches.filter(v => CommonUtils.isValidEmail(v)));
    } else {
      return [];
    }
  }

  static uniq(array: any[]) {
    return Array.from(new Set(array));
  }

}
